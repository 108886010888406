import colors from "styles/colors";
import { TIERS } from "constants/pricing";
import { DEV_PRODUCT_ID, WORK_MANAGEMENT_PRODUCT_ID, MARKETER_PRODUCT_ID, CRM_PRODUCT_ID, PROJECTS_PRODUCT_ID, SERVICE_PRODUCT_ID } from "constants/products";
import { DARK_CRM_GREEN_COLOR_NAME, WORK_OS_IRIS_COLOR_NAME, BRAND_GREEN_DARK_TINT_01_COLOR_NAME, BRAND_GREEN_DARKER_TINT_02, CRM_DARKER_TINT_02, BRAND_SERVICE_PRIMARY_COLOR_NAME, SERVICE_CTA_HOVER_BACKGROUND_COLOR, BRAND_BLACK_BACKGROUND_COLOR, WHITE_COLOR_NAME } from "styles/color-consts";
export default {
    core: {
        productId: WORK_MANAGEMENT_PRODUCT_ID,
        showInSwitcher: true,
        switcherLabel: "Work\nManagement",
        switcherIconColor: colors[WORK_OS_IRIS_COLOR_NAME],
        switcherTooltipLabelKey: "pricingPage.pricingData.productSwitcher.core.tooltip",
        orderInSwitcher: 2,
        showLearnMoreVideo: true,
        label: "pricingPage.products.core.title",
        color: colors["product-core"],
        ctaHoverBackgroundColor: colors["product-core"],
        ctaHoverBorderColor: colors["product-core"],
        primaryColor: colors[WORK_OS_IRIS_COLOR_NAME],
        secondaryColor: colors["brand-iris-hover"],
        mostPopularTierBySeat: {
            0: TIERS.STANDARD,
            25: TIERS.PRO,
            100: TIERS.ENTERPRISE
        },
        tiers: [
            {
                name: TIERS.FREE,
                features: [
                    "upTo3Boards",
                    "unlimitedDocs",
                    "200plusTemplates",
                    "limitedColumnTypes",
                    "iosAndAndroidApps"
                ],
                maxDiffFeatures: [
                    "upTo3Boards",
                    "unlimitedDocs",
                    "200plusTemplates",
                    "limitedColumnTypes",
                    "iosAndAndroidApps"
                ]
            },
            {
                name: TIERS.BASIC,
                features: [
                    "unlimitedFreeViewers",
                    "unlimitedItems",
                    "5GbFileStorage",
                    "prioritisedCustomerSupport",
                    "dashboardBasedOn1Board"
                ],
                maxDiffFeatures: [
                    "unlimitedFreeViewers",
                    "unlimitedItems",
                    "activityLog1Week",
                    "5GbFileStorage",
                    "prioritisedCustomerSupport",
                    "dashboardBasedOn1Board"
                ]
            },
            {
                name: TIERS.STANDARD,
                features: [
                    "timelineAndGanttViews",
                    "calendarView",
                    "guestAccess",
                    "automations250",
                    "integrations250",
                    "dashboardCombined5boards"
                ],
                maxDiffFeatures: [
                    "timelineAndGanttViews",
                    "calendarView",
                    "activityLog6Month",
                    "guestAccess",
                    "automations250",
                    "integrations250",
                    "dashboardCombined5boards_max_diff"
                ]
            },
            {
                name: TIERS.PRO,
                features: [
                    "privateBoardsAndWorkdocs",
                    "chartView",
                    "timeTracking",
                    "formulaColumn",
                    "automations25K",
                    "integrations25K",
                    "dashboardCombined10boards"
                ],
                maxDiffFeatures: [
                    "privateBoardsAndWorkdocs",
                    "20portfolios",
                    "activityLog1Year",
                    "workloadManagement",
                    "automations25K",
                    "integrations25K",
                    "dashboardCombined10boards_max_diff",
                    "unlimitedForms",
                    "100GbFileStorage",
                    "dependencies"
                ]
            },
            {
                name: TIERS.ENTERPRISE,
                features: [
                    "enterpriseAutomationsAndIntegrations",
                    "multiLevelPermissions",
                    "enterpriseSecurityAndGovernance",
                    "advancedReportingAndAnalytics",
                    "tailoredOnboarding",
                    "premiumSupport",
                    "dashboardCombined50boards"
                ],
                maxDiffFeatures: [
                    "enterpriseAutomationsAndIntegrations",
                    "multiLevelPermissions",
                    "enterpriseSecurityAndGovernance",
                    "unlimitedPortfolios",
                    "advancedDependencies",
                    "dashboardCombined50boards_max_diff",
                    "advancedReportingAndAnalytics",
                    "premiumSupport",
                    "tailoredOnboarding"
                ]
            }
        ],
        featuresList: [
            {
                sectionName: "essentials",
                features: [
                    {
                        featureName: "maximumNumberOfSeats",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.upTo2Seat",
                            [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
                            [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
                            [TIERS.PRO]: "pricingPage.features.plans.unlimited",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
                        }
                    },
                    {
                        featureName: "items",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.upToOneK",
                            [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
                            [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
                            [TIERS.PRO]: "pricingPage.features.plans.unlimited",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
                        }
                    },
                    {
                        featureName: "fileStorage",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.storage_free",
                            [TIERS.BASIC]: "pricingPage.features.plans.storage_basic",
                            [TIERS.STANDARD]: "pricingPage.features.plans.storage_standard",
                            [TIERS.PRO]: "pricingPage.features.plans.storage_pro",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.storage_enterprise"
                        }
                    },
                    {
                        featureName: "activityLog",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.one_week",
                            [TIERS.BASIC]: "pricingPage.features.plans.one_week",
                            [TIERS.STANDARD]: "pricingPage.features.plans.six_months",
                            [TIERS.PRO]: "pricingPage.features.plans.one_year",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.five_years"
                        }
                    },
                    {
                        featureName: "unlimitedBoards",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "unlimitedDocs",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "over20ColumnTypes",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "200plusTemplates",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "iosAndAndroidApps",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "unlimitedMessagesUpdates",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "integrations",
                        tierColumnValues: {
                            [TIERS.STANDARD]: "pricingPage.features.plans.integrations_standard",
                            [TIERS.PRO]: "pricingPage.features.plans.integrations_pro",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.integrations_enterprise"
                        }
                    },
                    {
                        featureName: "automations",
                        tierColumnValues: {
                            [TIERS.STANDARD]: "pricingPage.features.plans.automations_standard",
                            [TIERS.PRO]: "pricingPage.features.plans.automations_pro",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.automations_enterprise"
                        }
                    },
                    {
                        featureName: "unlimitedFreeViewers",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "collaboration",
                features: [
                    {
                        featureName: "embeddedDocuments",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "updatesSection",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "zoomIntegration",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "guestAccess",
                        tierColumnValues: {
                            [TIERS.STANDARD]: "pricingPage.features.plans.guests_four_one",
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "productivity",
                features: [
                    {
                        featureName: "ShareableForms",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: "pricingPage.features.descriptions.shareableForms.plusBranded",
                            [TIERS.ENTERPRISE]: "pricingPage.features.descriptions.shareableForms.plusConditional"
                        }
                    },
                    {
                        featureName: "customNotifications",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "customFields",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "premiumIntegrations",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "timeTracking",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "formulaColumn",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dependencyColumn",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "workload",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "viewsAndReporting",
                features: [
                    {
                        featureName: "activityLog",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.one_week",
                            [TIERS.BASIC]: "pricingPage.features.plans.one_week",
                            [TIERS.STANDARD]: "pricingPage.features.plans.six_months",
                            [TIERS.PRO]: "pricingPage.features.plans.one_year",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.five_years"
                        }
                    },
                    {
                        featureName: "dashboards",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.dashboards_basic",
                            [TIERS.BASIC]: "pricingPage.features.plans.dashboards_basic",
                            [TIERS.STANDARD]: "pricingPage.features.plans.dashboards_standard",
                            [TIERS.PRO]: "pricingPage.features.plans.dashboards_pro",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.dashboards_enterprise"
                        }
                    },
                    {
                        featureName: "kanbanView",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "timeLineView",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "calendarView",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "mapView",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "chartView",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "support",
                features: [
                    {
                        featureName: "selfServeKnowledgeBase",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "247CustomerSupport",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dailyLiveWebinars",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dedicatedCsManager",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "99SLA",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "securityAndPrivacy",
                features: [
                    {
                        featureName: "soc2compliance",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "twoFactorAuth",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "privateBoardAndDocs",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "googleAuth",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "sso",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "hippaCompliance",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "integrationPermissions",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "ipRestrictions",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "contentDirectory",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "administrationAndControl",
                features: [
                    {
                        featureName: "boardAdmins",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "scimProvisioning",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "auditLog",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "sessionManagement",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "panicMode",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "privateWorkspaces",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "advancedAccountPermissions",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "enterpriseReportingAndAnalytics",
                features: [
                    {
                        featureName: "workPerformanceInsights",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dashboardEmailNotification",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "pivotAnalysisAndReports",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            }
        ],
        labeledTierFeatures: {}
    },
    marketing: {
        productId: MARKETER_PRODUCT_ID,
        showInSwitcher: false,
        switcherLabel: "Marketer",
        switcherIconColor: colors["product-marketing"],
        label: "pricingPage.products.marketing.title",
        orderInSwitcher: 4,
        showLearnMoreVideo: false,
        mostPopularTierBySeat: {
            0: TIERS.STANDARD,
            25: TIERS.PRO,
            100: TIERS.ENTERPRISE
        },
        color: colors["product-marketing"],
        ctaHoverBackgroundColor: colors["product-marketing"],
        ctaHoverBorderColor: colors["product-marketing"],
        ctaHoverTextColor: colors[BRAND_BLACK_BACKGROUND_COLOR],
        primaryColor: colors["product-marketing"],
        secondaryColor: colors["product-marketing"],
        tiers: [
            {
                name: TIERS.FREE,
                features: [
                    "upTo2Members",
                    "upTo1000Items",
                    "simpleMarketingTaskManagement",
                    "basicFilterAndSearch"
                ]
            },
            {
                name: TIERS.BASIC,
                features: [
                    "unlimitedFreeViewers",
                    "unlimitedItems",
                    "contentAndCreativeAssetLibraries",
                    "digitalAssetManagementIntegrations",
                    "creativeRequestForms",
                    "basicActivityTracking",
                    "marketingAnalytics",
                    "iosAndAndroidApps"
                ]
            },
            {
                name: TIERS.STANDARD,
                features: [
                    "contentCalendarView",
                    "campaignGanttView",
                    "readyMadeDashboards",
                    "exclusiveMarketingIntegrations",
                    "customAutomations250",
                    "integrations250",
                    "marketingAnalytics"
                ]
            },
            {
                name: TIERS.PRO,
                features: [
                    "privateBoardsAndWorkDocs",
                    "annotationsMultipleFileTypes",
                    "versionControl",
                    "campaignGanttWithDeps",
                    "creativeCloudPlugin",
                    "shareableBriefs",
                    "customAutomations25k",
                    "integrations25k",
                    "marketingAnalytics"
                ]
            },
            {
                name: TIERS.ENTERPRISE,
                features: [
                    "enterpriseAutomationsAndIntegrations",
                    "campaignApprovalAdvanced",
                    "enterpriseSecurityAndGovernance",
                    "10TBstorage",
                    "salesforceAndJiraIntegration",
                    "marketingAnalytics",
                    "premiumSupport"
                ]
            }
        ],
        featuresList: [
            {
                sectionName: "essentials",
                features: [
                    {
                        featureName: "30PlusReadyMadeTemplates",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "upTo2TeamMembers",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.upTo2Seat",
                            [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
                            [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
                            [TIERS.PRO]: "pricingPage.features.plans.unlimited",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
                        }
                    },
                    {
                        featureName: "items",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.upToOneK",
                            [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
                            [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
                            [TIERS.PRO]: "pricingPage.features.plans.unlimited",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
                        }
                    },
                    {
                        featureName: "contentCalendarView",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "eventManagement",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "annotationsMultipleFileTypes",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "versionControl",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "integrations",
                        tierColumnValues: {
                            [TIERS.STANDARD]: "pricingPage.features.plans.integrations_standard",
                            [TIERS.PRO]: "pricingPage.features.plans.integrations_pro",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.integrations_enterprise"
                        }
                    },
                    {
                        featureName: "automations",
                        tierColumnValues: {
                            [TIERS.STANDARD]: "pricingPage.features.plans.automations_standard",
                            [TIERS.PRO]: "pricingPage.features.plans.automations_pro",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.automations_enterprise"
                        }
                    },
                    {
                        featureName: "iosAndAndroidApps",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "unlimitedBoards",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "unlimitedDocs",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "over20ColumnTypes",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "200plusTemplates",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "unlimitedFreeViewers",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "shareableRequestForms",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "privateBoardsAndWorkDocs",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dynamicForms",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "activityLog",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.one_week",
                            [TIERS.BASIC]: "pricingPage.features.plans.one_week",
                            [TIERS.STANDARD]: "pricingPage.features.plans.six_months",
                            [TIERS.PRO]: "pricingPage.features.plans.one_year",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.five_years"
                        }
                    }
                ]
            },
            {
                sectionName: "campaignManagement",
                features: [
                    {
                        featureName: "contentAndCreativeAssetLibraries",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "googleAds",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "facebookAds",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "campaignGanttWithDeps",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "marketingAnalytics",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "campaignApprovalAdvanced",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "digitalAssetManagement",
                features: [
                    {
                        featureName: "contentAndBrandManagement",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "embeddedDocuments",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "guestAccess",
                        tierColumnValues: {
                            [TIERS.STANDARD]: "pricingPage.features.plans.guests_four_one",
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "creativeCloudPlugin",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "1TBStorage",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "5TBStorage",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "assetApproval",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "productivity",
                features: [
                    {
                        featureName: "customNotifications",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "timeTracking",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "formulaColumn",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dependencyColumn",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "premiumIntegrations",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "viewsAndReporting",
                features: [
                    {
                        featureName: "dashboards",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.dashboards_basic",
                            [TIERS.BASIC]: "pricingPage.features.plans.dashboards_basic",
                            [TIERS.STANDARD]: "pricingPage.features.plans.dashboards_standard",
                            [TIERS.PRO]: "pricingPage.features.plans.dashboards_pro",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.dashboards_enterprise"
                        }
                    },
                    {
                        featureName: "kanbanView",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "timeLineView",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "calendarView",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "mapView",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "chartView",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "workload",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "support",
                features: [
                    {
                        featureName: "selfServeKnowledgeBase",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "247CustomerSupport",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dailyLiveWebinars",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dedicatedCsManager",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "99SLA",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "securityAndPrivacy",
                features: [
                    {
                        featureName: "soc2compliance",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "twoFactorAuth",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "privateBoardAndDocs",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "googleAuth",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "sso",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "hippaCompliance",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "integrationPermissions",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "ipRestrictions",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "contentDirectory",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "administrationAndControl",
                features: [
                    {
                        featureName: "boardAdmins",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "scimProvisioning",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "auditLog",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "sessionManagement",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "panicMode",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "privateWorkspaces",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "advancedAccountPermissions",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "enterpriseReportingAndAnalytics",
                features: [
                    {
                        featureName: "workPerformanceInsights",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dashboardEmailNotification",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "pivotAnalysisAndReports",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            }
        ],
        labeledTierFeatures: {}
    },
    crm: {
        productId: CRM_PRODUCT_ID,
        showInSwitcher: true,
        switcherLabel: "CRM",
        switcherIconColor: colors["crm-green"],
        switcherTooltipLabelKey: "pricingPage.pricingData.productSwitcher.crm.tooltip",
        label: "pricingPage.products.crm.title",
        orderInSwitcher: 1,
        showLearnMoreVideo: false,
        color: colors["crm-green"],
        ctaHoverBackgroundColor: colors["crm-green"],
        ctaHoverBorderColor: colors["crm-green"],
        ctaHoverTextColor: colors[BRAND_BLACK_BACKGROUND_COLOR],
        primaryColor: colors[DARK_CRM_GREEN_COLOR_NAME],
        secondaryColor: colors[CRM_DARKER_TINT_02],
        mostPopularTierBySeat: {
            0: TIERS.STANDARD,
            25: TIERS.PRO,
            100: TIERS.ENTERPRISE
        },
        showFreeTierComingSoon: false,
        tiers: [
            {
                name: TIERS.BASIC,
                features: [
                    "customizablePipelines",
                    "unlimitedContacts",
                    "unlimitedBoard",
                    "leadManagement",
                    "iosAndAndroidApps",
                    "unlimitedFreeViewers",
                    "dashboardCombined1board"
                ]
            },
            {
                name: TIERS.STANDARD,
                features: [
                    "accountContactAndDealManagement",
                    "twoWayEmailIntegration",
                    "aiEmailGenerator",
                    "activityManagement",
                    "mergeDuplicateData",
                    "automations",
                    "integrations",
                    "dashboardCombined5boards"
                ]
            },
            {
                name: TIERS.PRO,
                features: [
                    "salesForeCasting",
                    "emailTemplates",
                    "emailTracking",
                    "sequences",
                    "quotesAndInvoices",
                    "massEmails",
                    "googleCalendarSync",
                    "salesAnalytics",
                    "automations",
                    "integrations",
                    "dashboardCombined10boards"
                ]
            },
            {
                name: TIERS.ENTERPRISE,
                features: [
                    "leadScoring",
                    "mandatoryFields",
                    "teamGoals",
                    "advancedAnalytics",
                    "accountManagement",
                    "documentsForSales",
                    "enterpriseAutomationsAndIntegrations",
                    "enterpriseSecurityAndGovernance",
                    "multiLevelPermissions",
                    "hippaCompliance"
                ]
            }
        ],
        featuresList: [
            {
                sectionName: "essentials",
                features: [
                    {
                        featureName: "contactsDatabase",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.upToOneK",
                            [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
                            [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
                            [TIERS.PRO]: "pricingPage.features.plans.unlimited",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
                        }
                    },
                    {
                        featureName: "maximumNumberOfSeats",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.upTo2Seat",
                            [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
                            [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
                            [TIERS.PRO]: "pricingPage.features.plans.unlimited",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
                        }
                    },
                    {
                        featureName: "webFormsToCaptureLeads",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "basicLeadContractAndDealManagement",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "unlimitedCustomizablePipelines",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "unlimitedBoards",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "unlimitedDocs",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "over20ColumnTypes",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "200plusTemplates",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "iosAndAndroidApps",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "unlimitedFreeViewers",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "leadManagement",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "accountAndContractManagement",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dealManagement",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "items",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.upToOneK",
                            [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
                            [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
                            [TIERS.PRO]: "pricingPage.features.plans.unlimited",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
                        }
                    },
                    {
                        featureName: "fileStorage",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.storage_free",
                            [TIERS.BASIC]: "pricingPage.features.plans.storage_basic",
                            [TIERS.STANDARD]: "pricingPage.features.plans.storage_standard",
                            [TIERS.PRO]: "pricingPage.features.plans.storage_pro",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.storage_enterprise"
                        }
                    },
                    {
                        featureName: "activityLog",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.one_week",
                            [TIERS.BASIC]: "pricingPage.features.plans.one_week",
                            [TIERS.STANDARD]: "pricingPage.features.plans.six_months",
                            [TIERS.PRO]: "pricingPage.features.plans.one_year",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.five_years"
                        }
                    }
                ]
            },
            {
                sectionName: "customerRelationship",
                features: [
                    {
                        featureName: "twoWayEmailIntegration",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "activityManagement",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "quotesAndInvoices",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "emailTemplates",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "emailTracking",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "massEmails",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "massEmailsTracking",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "htmlMassEmails",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "googleCalendarSync",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "customizableEmailSignatures",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "productivity",
                features: [
                    {
                        featureName: "customNotifications",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "aiEmailGenerator",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "mergeDuplicateData",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "integrations",
                        tierColumnValues: {
                            [TIERS.STANDARD]: "pricingPage.features.plans.integrations_standard",
                            [TIERS.PRO]: "pricingPage.features.plans.integrations_pro",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.integrations_enterprise"
                        }
                    },
                    {
                        featureName: "automations",
                        tierColumnValues: {
                            [TIERS.STANDARD]: "pricingPage.features.plans.automations_standard",
                            [TIERS.PRO]: "pricingPage.features.plans.automations_pro",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.automations_enterprise"
                        }
                    },
                    {
                        featureName: "docusignIntegration",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "airCallIntegration",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "pandaDocIntegration",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "sequences",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "mailChimpIntegration",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "hubSpotIntegration",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "facebookAdsIntegration",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "timeTracking",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "formulaColumn",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "salesForceIntegration",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "leadScoring",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "mandatoryFields",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "duplicateWarning",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "insightsAndReporting",
                features: [
                    {
                        featureName: "salesForecasting",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "salesAnalytics",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "advancedAnalytics",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "teamGoals",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dashboards",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.dashboards_basic",
                            [TIERS.BASIC]: "pricingPage.features.plans.dashboards_basic",
                            [TIERS.STANDARD]: "pricingPage.features.plans.dashboards_standard",
                            [TIERS.PRO]: "pricingPage.features.plans.dashboards_pro",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.dashboards_enterprise"
                        }
                    }
                ]
            },
            {
                sectionName: "postSalesManagement",
                features: [
                    {
                        featureName: "accountManagement",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "clientProjects",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "collectionTracking",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "salesOperations",
                features: [
                    {
                        featureName: "headcountPlanning",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "salesTeamOnboarding",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "salesCollateral",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "documentsForSales",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "legalAndSecurityRequests",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "collaboration",
                features: [
                    {
                        featureName: "embeddedDocuments",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "updatesSection",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "zoomIntegration",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "guestAccess",
                        tierColumnValues: {
                            [TIERS.STANDARD]: "pricingPage.features.plans.guests_four_one",
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "viewsAndReporting",
                features: [
                    {
                        featureName: "kanbanView",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "timeLineView",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "calendarView",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "mapView",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "chartView",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "workload",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "support",
                features: [
                    {
                        featureName: "selfServeKnowledgeBase",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "247CustomerSupport",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dailyLiveWebinars",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dedicatedCsManager",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "99SLA",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "securityAndPrivacy",
                features: [
                    {
                        featureName: "soc2compliance",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "twoFactorAuth",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "privateBoardAndDocs",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "googleAuth",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "sso",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "hippaCompliance",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "integrationPermissions",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "ipRestrictions",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "contentDirectory",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "administrationAndControl",
                features: [
                    {
                        featureName: "boardAdmins",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "scimProvisioning",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "auditLog",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "sessionManagement",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "panicMode",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "privateWorkspaces",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "advancedAccountPermissions",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "enterpriseReportingAndAnalytics",
                features: [
                    {
                        featureName: "workPerformanceInsights",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dashboardEmailNotification",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "pivotAnalysisAndReports",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            }
        ],
        labeledTierFeatures: {
            sequences: "pricingPage.feature_label.new"
        }
    },
    projectManagement: {
        productId: PROJECTS_PRODUCT_ID,
        showInSwitcher: false,
        switcherLabel: "Projects",
        switcherIconColor: colors["product-project-management"],
        orderInSwitcher: 5,
        showLearnMoreVideo: false,
        label: "pricingPage.products.projectManagement.title",
        color: colors["product-project-management"],
        ctaHoverBackgroundColor: colors["product-project-management"],
        ctaHoverBorderColor: colors["product-project-management"],
        ctaHoverTextColor: colors[BRAND_BLACK_BACKGROUND_COLOR],
        primaryColor: colors["product-project-management"],
        secondaryColor: colors["product-project-management"],
        mostPopularTierBySeat: {
            0: TIERS.STANDARD,
            25: TIERS.PRO,
            100: TIERS.ENTERPRISE
        },
        tiers: [
            {
                name: TIERS.FREE,
                features: [
                    "upTo2Members",
                    "upTo1000Tasks",
                    "brainstormingInitiativesAndPlanning"
                ]
            },
            {
                name: TIERS.BASIC,
                features: [
                    "freeViewingAccess",
                    "unlimitedTasks",
                    "projectRequests",
                    "centralizedProjectCommunication",
                    "agileProjectsWithKanbanView",
                    "dashboardCombined1board",
                    "mobileApps"
                ]
            },
            {
                name: TIERS.STANDARD,
                features: [
                    "calendarViewAndIntegration",
                    "ganttChart",
                    "guestAccess",
                    "milestones",
                    "simpleOverviewAcrossProjects",
                    "taskRollup",
                    "projectAutomations",
                    "integrations250",
                    "dashboardCombined5boards"
                ]
            },
            {
                name: TIERS.PRO,
                features: [
                    "privateBoardsAndWorkDocs",
                    "dependencies",
                    "resourceAllocation",
                    "criticalPath",
                    "baseline",
                    "projectAutomations",
                    "integrations25k",
                    "dashboardCombined10boards"
                ]
            },
            {
                name: TIERS.ENTERPRISE,
                features: [
                    "enterpriseAutomationsAndIntegrations",
                    "enterpriseSecurityAndGovernance",
                    "advancedReportingAndAnalytics",
                    "multiLevelPermissions",
                    "portfolioManagementAndDashboard",
                    "projectsLifeCycle",
                    "dashboardCombined50boards"
                ]
            }
        ],
        featuresList: [
            {
                sectionName: "essentials",
                features: [
                    {
                        featureName: "maximumNumberOfSeats",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.upTo2Seat",
                            [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
                            [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
                            [TIERS.PRO]: "pricingPage.features.plans.unlimited",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
                        }
                    },
                    {
                        featureName: "items",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.upToOneK",
                            [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
                            [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
                            [TIERS.PRO]: "pricingPage.features.plans.unlimited",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
                        }
                    },
                    {
                        featureName: "activityLog",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.one_week",
                            [TIERS.BASIC]: "pricingPage.features.plans.one_week",
                            [TIERS.STANDARD]: "pricingPage.features.plans.six_months",
                            [TIERS.PRO]: "pricingPage.features.plans.one_year",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.five_years"
                        }
                    },
                    {
                        featureName: "fileStorage",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.storage_free",
                            [TIERS.BASIC]: "pricingPage.features.plans.storage_basic",
                            [TIERS.STANDARD]: "pricingPage.features.plans.storage_standard",
                            [TIERS.PRO]: "pricingPage.features.plans.storage_pro",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.storage_enterprise"
                        }
                    },
                    {
                        featureName: "importFromExcel",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "unlimitedBoards",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "unlimitedDocs",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "over20ColumnTypes",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "simpleProjectOverview",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "manageProjectsTasksAndSubtasks",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "freeViewingAccess",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "centralizedProjectCommunication",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "milestones",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "simpleOverviewAcrossProjects",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "taskRollup",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "projectAndTaskFilters",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "criticalPath",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "resourceAllocation",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "effortManagement",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "budgetAndCostManagement",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "baseline",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dependencies",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "projectApprovals",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "portfolioManagementAndDashboard",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "projectAndPortfolioRiskManagement",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "trackingProgressAcrossPortfolioAndPrograms",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "collaboration",
                features: [
                    {
                        featureName: "embeddedDocuments",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "updatesSection",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "zoomIntegration",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "guestAccess",
                        tierColumnValues: {
                            [TIERS.STANDARD]: "pricingPage.features.plans.guests_four_one",
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "productivity",
                features: [
                    {
                        featureName: "ShareableForms",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: "pricingPage.features.descriptions.shareableForms.plusBranded",
                            [TIERS.ENTERPRISE]: "pricingPage.features.descriptions.shareableForms.plusConditional"
                        }
                    },
                    {
                        featureName: "customNotifications",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "integrations",
                        tierColumnValues: {
                            [TIERS.STANDARD]: "pricingPage.features.plans.integrations_standard",
                            [TIERS.PRO]: "pricingPage.features.plans.integrations_pro",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.integrations_enterprise"
                        }
                    },
                    {
                        featureName: "automations",
                        tierColumnValues: {
                            [TIERS.STANDARD]: "pricingPage.features.plans.automations_standard",
                            [TIERS.PRO]: "pricingPage.features.plans.automations_pro",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.automations_enterprise"
                        }
                    },
                    {
                        featureName: "microsoftTeamsApp",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "slackIntegration",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "timeTracking",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "formulaColumn",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "advancedIntegrationsWithJiraServerSalesforceAndMore",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "viewsAndReporting",
                features: [
                    {
                        featureName: "dashboards",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.dashboards_basic",
                            [TIERS.BASIC]: "pricingPage.features.plans.dashboards_basic",
                            [TIERS.STANDARD]: "pricingPage.features.plans.dashboards_standard",
                            [TIERS.PRO]: "pricingPage.features.plans.dashboards_pro",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.dashboards_enterprise"
                        }
                    },
                    {
                        featureName: "agileProjectsWithKanbanView",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "timeLineView",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "calendarViewAndIntegrations",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "mapView",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "chartView",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "workload",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "goalTracking",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "plannedVsActualAnalysis",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "enterpriseCostManagement",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "support",
                features: [
                    {
                        featureName: "selfServeKnowledgeBase",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "247CustomerSupport",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dailyLiveWebinars",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dedicatedCsManager",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "99SLA",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "securityAndPrivacy",
                features: [
                    {
                        featureName: "soc2compliance",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "twoFactorAuth",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "privateBoardAndDocs",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "googleAuth",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "sso",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "hippaCompliance",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "integrationPermissions",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "ipRestrictions",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "contentDirectory",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "administrationAndControl",
                features: [
                    {
                        featureName: "boardAdmins",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "scimProvisioning",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "auditLog",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "sessionManagement",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "panicMode",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "privateWorkspaces",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "advancedAccountPermissions",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "enterpriseReportingAndAnalytics",
                features: [
                    {
                        featureName: "workPerformanceInsights",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dashboardEmailNotification",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "pivotAnalysisAndReports",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            }
        ],
        labeledTierFeatures: {}
    },
    software: {
        productId: DEV_PRODUCT_ID,
        showInSwitcher: true,
        switcherLabel: "Dev",
        switcherIconColor: colors["dev-orange"],
        orderInSwitcher: 3,
        showLearnMoreVideo: false,
        label: "pricingPage.products.software.title",
        switcherTooltipLabelKey: "pricingPage.pricingData.productSwitcher.software.tooltip",
        color: colors["dev-orange"],
        ctaHoverBackgroundColor: colors["dev-orange"],
        ctaHoverBorderColor: colors["dev-orange"],
        ctaHoverTextColor: colors[BRAND_BLACK_BACKGROUND_COLOR],
        primaryColor: colors[BRAND_GREEN_DARK_TINT_01_COLOR_NAME],
        secondaryColor: colors[BRAND_GREEN_DARKER_TINT_02],
        mostPopularTierBySeat: {
            0: TIERS.STANDARD,
            25: TIERS.PRO,
            100: TIERS.ENTERPRISE
        },
        showFreeTierComingSoon: false,
        tiers: [
            {
                name: TIERS.BASIC,
                features: [
                    "unlimitedViewers",
                    "unlimitedItems",
                    "templatesForDev",
                    "wikiAndDocumentations",
                    "dashboardCombined1board",
                    "mobileApps"
                ]
            },
            {
                name: TIERS.STANDARD,
                features: [
                    "ganttChart",
                    "guestAccess",
                    "sprintManagement",
                    "roadmapPlanning",
                    "githubApp",
                    "automations",
                    "integrations",
                    "dashboardCombined5boards"
                ]
            },
            {
                name: TIERS.PRO,
                features: [
                    "privateBoards",
                    "timeTracking",
                    "agileReporting",
                    "roadmapWithDeps",
                    "customerFeedbackAndRequests",
                    "automations",
                    "integrations",
                    "dashboardCombined10boards"
                ]
            },
            {
                name: TIERS.ENTERPRISE,
                features: [
                    "enterpriseAutomationsAndIntegrations",
                    "enterpriseSecurityAndGovernance",
                    "advancedReportingAndAnalytics",
                    "multiLevelPermissions",
                    "tailoredOnboarding",
                    "premiumSupport",
                    "dashboardCombined50boards"
                ]
            }
        ],
        featuresList: [
            {
                sectionName: "essentials",
                features: [
                    {
                        featureName: "maximumNumberOfSeats",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.upTo2Seat",
                            [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
                            [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
                            [TIERS.PRO]: "pricingPage.features.plans.unlimited",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
                        }
                    },
                    {
                        featureName: "items",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.upToOneK",
                            [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
                            [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
                            [TIERS.PRO]: "pricingPage.features.plans.unlimited",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
                        }
                    },
                    {
                        featureName: "fileStorage",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.storage_free",
                            [TIERS.BASIC]: "pricingPage.features.plans.storage_basic",
                            [TIERS.STANDARD]: "pricingPage.features.plans.storage_standard",
                            [TIERS.PRO]: "pricingPage.features.plans.storage_pro",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.storage_enterprise"
                        }
                    },
                    {
                        featureName: "wikiAndDocumentations",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "sprintManagement",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "kanbanView",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "backlog",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "bugTracking",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "unlimitedBoards",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "iosAndAndroidApps",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "freeViewingAccess",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "gitHubApp",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "gitlabIntegration",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "jiraIntegration",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "slackIntegration",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "microsoftTeamsApp",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "planning",
                features: [
                    {
                        featureName: "customerFeedbackAndRequests",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "featuresPrioritization",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "roadmapWithDependencies",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "documentSpecsAndProductRequirementDocs",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "productPlanning",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "featureRequestsAndReleaseManagement",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "collaboration",
                features: [
                    {
                        featureName: "unlimitedMessagesUpdates",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "embeddedDocuments",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "updatesSection",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "miroIntegration",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "figmaIntegration",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "zoomIntegration",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "productivity",
                features: [
                    {
                        featureName: "customNotifications",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "manageDifferentIssueTypes",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "ShareableForms",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: "pricingPage.features.descriptions.shareableForms.plusBranded",
                            [TIERS.ENTERPRISE]: "pricingPage.features.descriptions.shareableForms.plusConditional"
                        }
                    },
                    {
                        featureName: "shareableBoardsWithExternalStakeholders",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "timeTracking",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "formulaColumn",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "viewsAndReporting",
                features: [
                    {
                        featureName: "dashboards",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.dashboards_basic",
                            [TIERS.BASIC]: "pricingPage.features.plans.dashboards_basic",
                            [TIERS.STANDARD]: "pricingPage.features.plans.dashboards_standard",
                            [TIERS.PRO]: "pricingPage.features.plans.dashboards_pro",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.dashboards_enterprise"
                        }
                    },
                    {
                        featureName: "activityLog",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.one_week",
                            [TIERS.BASIC]: "pricingPage.features.plans.one_week",
                            [TIERS.STANDARD]: "pricingPage.features.plans.six_months",
                            [TIERS.PRO]: "pricingPage.features.plans.one_year",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.five_years"
                        }
                    },
                    {
                        featureName: "timeLineView",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "calendarViewAndIntegrations",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "mapView",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "timelineAndGanttViews",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "chartView",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "workload",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "agileReporting",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "support",
                features: [
                    {
                        featureName: "selfServeKnowledgeBase",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "247CustomerSupport",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dailyLiveWebinars",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dedicatedCsManager",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "99SLA",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "securityAndPrivacy",
                features: [
                    {
                        featureName: "soc2compliance",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "twoFactorAuth",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "privateBoardAndDocs",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "googleAuth",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "sso",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "hippaCompliance",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "integrationPermissions",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "contentDirectory",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "ipRestrictions",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "administrationAndControl",
                features: [
                    {
                        featureName: "boardAdmins",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "scimProvisioning",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "auditLog",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "sessionManagement",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "panicMode",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "privateWorkspaces",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "advancedAccountPermissions",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "enterpriseReportingAndAnalytics",
                features: [
                    {
                        featureName: "workPerformanceInsights",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dashboardEmailNotification",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "pivotAnalysisAndReports",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            }
        ],
        labeledTierFeatures: {}
    },
    service: {
        productId: SERVICE_PRODUCT_ID,
        showInSwitcher: false,
        switcherLabel: "Service",
        switcherIconColor: colors[BRAND_SERVICE_PRIMARY_COLOR_NAME],
        orderInSwitcher: 3,
        showLearnMoreVideo: false,
        label: "pricingPage.products.service.title",
        switcherTooltipLabelKey: "pricingPage.pricingData.productSwitcher.service.tooltip",
        color: colors[BRAND_SERVICE_PRIMARY_COLOR_NAME],
        ctaHoverBackgroundColor: colors[SERVICE_CTA_HOVER_BACKGROUND_COLOR],
        ctaHoverBorderColor: colors[SERVICE_CTA_HOVER_BACKGROUND_COLOR],
        ctaHoverTextColor: colors[WHITE_COLOR_NAME],
        primaryColor: colors[BRAND_SERVICE_PRIMARY_COLOR_NAME],
        secondaryColor: colors[BRAND_SERVICE_PRIMARY_COLOR_NAME],
        mostPopularTierBySeat: {
            0: TIERS.STANDARD,
            25: TIERS.PRO,
            100: TIERS.ENTERPRISE
        },
        showFreeTierComingSoon: true,
        tiers: [
            {
                name: TIERS.STANDARD,
                features: [
                    "unlimitedTickets",
                    "ticketEmailWorkForms",
                    "emailIntegration",
                    "emailServerHosted",
                    "aiAutomations",
                    "ticketBoards",
                    "serviceAutomations",
                    "dashboardBasedBoard"
                ]
            },
            {
                name: TIERS.PRO,
                features: [
                    "dynamicAutoResponses",
                    "aiAutomation",
                    "emailServerPrefix",
                    "ticketBoards",
                    "serviceAutomations",
                    "dashboard",
                    "analytics"
                ]
            },
            {
                name: TIERS.ENTERPRISE,
                features: [
                    "aiAutomations",
                    "ticketBoards",
                    "customizableEmailServers",
                    "enterpriseSupport",
                    "scaleAutomationsIntegrations",
                    "securityGovernance",
                    "permissions",
                    "hipaa",
                    "analytics"
                ]
            }
        ],
        featuresList: [
            {
                sectionName: "essentials",
                features: [
                    {
                        featureName: "maximumNumberOfSeats",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.upTo2Seat",
                            [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
                            [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
                            [TIERS.PRO]: "pricingPage.features.plans.unlimited",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
                        }
                    },
                    {
                        featureName: "items",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.upToOneK",
                            [TIERS.BASIC]: "pricingPage.features.plans.unlimited",
                            [TIERS.STANDARD]: "pricingPage.features.plans.unlimited",
                            [TIERS.PRO]: "pricingPage.features.plans.unlimited",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.unlimited"
                        }
                    },
                    {
                        featureName: "fileStorage",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.storage_free",
                            [TIERS.BASIC]: "pricingPage.features.plans.storage_basic",
                            [TIERS.STANDARD]: "pricingPage.features.plans.storage_standard",
                            [TIERS.PRO]: "pricingPage.features.plans.storage_pro",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.storage_enterprise"
                        }
                    },
                    {
                        featureName: "wikiAndDocumentations",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "sprintManagement",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "kanbanView",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "backlog",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "bugTracking",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "unlimitedBoards",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "iosAndAndroidApps",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "freeViewingAccess",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "gitHubApp",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "gitlabIntegration",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "jiraIntegration",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "slackIntegration",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "microsoftTeamsApp",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "planning",
                features: [
                    {
                        featureName: "customerFeedbackAndRequests",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "featuresPrioritization",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "roadmapWithDependencies",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "documentSpecsAndProductRequirementDocs",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "productPlanning",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "featureRequestsAndReleaseManagement",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "collaboration",
                features: [
                    {
                        featureName: "unlimitedMessagesUpdates",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "embeddedDocuments",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "updatesSection",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "miroIntegration",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "figmaIntegration",
                        tierColumnValues: {
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "zoomIntegration",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "productivity",
                features: [
                    {
                        featureName: "customNotifications",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "manageDifferentIssueTypes",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "ShareableForms",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: "pricingPage.features.descriptions.shareableForms.plusBranded",
                            [TIERS.ENTERPRISE]: "pricingPage.features.descriptions.shareableForms.plusConditional"
                        }
                    },
                    {
                        featureName: "shareableBoardsWithExternalStakeholders",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "timeTracking",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "formulaColumn",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "viewsAndReporting",
                features: [
                    {
                        featureName: "dashboards",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.dashboards_basic",
                            [TIERS.BASIC]: "pricingPage.features.plans.dashboards_basic",
                            [TIERS.STANDARD]: "pricingPage.features.plans.dashboards_standard",
                            [TIERS.PRO]: "pricingPage.features.plans.dashboards_pro",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.dashboards_enterprise"
                        }
                    },
                    {
                        featureName: "activityLog",
                        tierColumnValues: {
                            [TIERS.FREE]: "pricingPage.features.plans.one_week",
                            [TIERS.BASIC]: "pricingPage.features.plans.one_week",
                            [TIERS.STANDARD]: "pricingPage.features.plans.six_months",
                            [TIERS.PRO]: "pricingPage.features.plans.one_year",
                            [TIERS.ENTERPRISE]: "pricingPage.features.plans.five_years"
                        }
                    },
                    {
                        featureName: "timeLineView",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "calendarViewAndIntegrations",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "mapView",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "timelineAndGanttViews",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "chartView",
                        tierColumnValues: {
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "workload",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "agileReporting",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "support",
                features: [
                    {
                        featureName: "selfServeKnowledgeBase",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "247CustomerSupport",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dailyLiveWebinars",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dedicatedCsManager",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "99SLA",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "securityAndPrivacy",
                features: [
                    {
                        featureName: "soc2compliance",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "twoFactorAuth",
                        tierColumnValues: {
                            [TIERS.FREE]: true,
                            [TIERS.BASIC]: true,
                            [TIERS.STANDARD]: true,
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "privateBoardAndDocs",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "googleAuth",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "sso",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "hippaCompliance",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "integrationPermissions",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "contentDirectory",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "ipRestrictions",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "administrationAndControl",
                features: [
                    {
                        featureName: "boardAdmins",
                        tierColumnValues: {
                            [TIERS.PRO]: true,
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "scimProvisioning",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "auditLog",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "sessionManagement",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "panicMode",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "privateWorkspaces",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "advancedAccountPermissions",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            },
            {
                sectionName: "enterpriseReportingAndAnalytics",
                features: [
                    {
                        featureName: "workPerformanceInsights",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "dashboardEmailNotification",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    },
                    {
                        featureName: "pivotAnalysisAndReports",
                        tierColumnValues: {
                            [TIERS.ENTERPRISE]: true
                        }
                    }
                ]
            }
        ],
        labeledTierFeatures: {}
    }
};
